import uuid from 'uuid'
import _ from 'lodash'
import {NEW_PAGE_ROUTE} from '../constants'

function createPageSEOData(pageTitle, isPrivate) {
    return {
        title: isPrivate ? pageTitle : '{userName} | ' + pageTitle,
        description: '',
        keywords: '',
        noIndex: isPrivate.toString()
    }
}

async function connectPageToRouter({editorSDK, appToken, pageRef, pageUriSEO, urlOverride, isPrivate = false, routerConfig = {}}) {
    const guid = uuid.v4()
    const pageLink = urlOverride || pageUriSEO
    const pattern = isPrivate ? '/' + pageLink : '/{userName}/' + pageLink
    const routers = await editorSDK.routers.getAll(appToken)

    if (!routers || routers.length === 0) {
        throw new Error('Failed to retrieve the routers in #connectPageToRouter')
    }

    const privacy = isPrivate ? 'private' : 'public'
    const router = _.find(routers, r => r.config.type === privacy)

    if (!router) {
        throw isPrivate ?
            new Error('Could not find the private router in #connectPageToRouter') :
            new Error('Could not find the public router in #connectPageToRouter')
    }

    const routerRef = await editorSDK.routers.getByPrefix(appToken, {prefix: router.prefix})
    const {appData} = routerConfig || {}
    const {socialHome} = routerConfig
    await editorSDK.routers.pages.connect(appToken, {
        pageRef,
        routerRef,
        pageRoles: [guid]
    })
    let config = router.config
    const pageData = await editorSDK.pages.data.get(appToken, {pageRef})

    const newRouteConfig = {
        socialHome,
        appData,
        page: guid,
        seoData: createPageSEOData(pageData.title, isPrivate),
        title: pageData.title
    }

    _.set(config, 'patterns[' + pattern + ']', newRouteConfig)

    await editorSDK.routers.update(appToken, {
        config,
        routerRef
    })

    return routerRef
}

async function onRouterPageDelete(editorSDK, token, pageRole, pageRef) {
    const routers = await editorSDK.routers.getAll()
    for (let router of routers) {
        const patternToRemove = _.find(router.config.patterns, pattern => pattern.page === pageRole)
        const returnValue = _.findKey(router.config.patterns, patternToRemove)
        if (patternToRemove) {
            const {appDefinitionId} = patternToRemove.appData
            router.config.patterns = _.pickBy(router.config.patterns, p => p.page !== pageRole)
            const routerRef = await editorSDK.routers.getByPrefix(token, {prefix: router.prefix})
            await editorSDK.routers.update(token, {
                routerRef,
                config: router.config
            })
            if (pageRef) {
                await editorSDK.routers.pages.remove(token, {routerRef, pageRef})
            }

            return {returnValue, appDefinitionId}
        }
    }
    return {}
}

async function getPageRouterData(editorSDK, token, pageRef) {
    const routerRef = await editorSDK.routers.getByPage(token, {pageRef})
    const router = await editorSDK.routers.get(token, {routerRef})

    if (!router) {
        throw new Error('Could not retrieve the router in #getPageRouterData')
    }

    const pageRouterData = _.find(router.pages, page => page.pageRef.id === pageRef.id)
    const guid = pageRouterData.pageRoles[0]
    const data = _.find(router.config.patterns, p => p.page === guid)
    const pattern = _.findKey(router.config.patterns, p => p.page === guid)
    if (data) {
        data.routerRef = routerRef
        data.innerRoute = pattern
        data.prefix = router.prefix
        data.isPrivate = router.config.type === 'private'
    }

    return data
}


async function updatePageData(editorSDK, token, pageRef, dataFieldToUpdate, updatedData) {
    const routerRef = await editorSDK.routers.getByPage(token, {pageRef})
    const router = await editorSDK.routers.get(token, {routerRef})
    const pageRouterData = _.find(router.pages, (page) => {return page.pageRef.id === pageRef.id})
    const guid = pageRouterData.pageRoles[0]
    const pattern = _.findKey(router.config.patterns, (p) => {return p.page === guid})
    const patternData = _.find(router.config.patterns, (p) => {return p.page === guid})

    patternData[dataFieldToUpdate] = typeof updatedData === 'string' ? updatedData : _.assign({}, patternData[dataFieldToUpdate], updatedData)

    let config = router.config
    _.set(config, 'patterns[' + pattern + ']', patternData)

    await editorSDK.routers.update(token, {
        routerRef,
        config
    })
    return routerRef
}

async function changePagePattern(editorSDK, token, pageRef, newSuffix) {
    const routerRef = await editorSDK.routers.getByPage(token, {pageRef})
    const router = await editorSDK.routers.get(token, {routerRef})
    const pageRouterData = _.find(router.pages, (page) => {return page.pageRef.id === pageRef.id})
    const guid = pageRouterData.pageRoles[0]
    const pattern = _.findKey(router.config.patterns, (p) => {return p.page === guid})
    const patternData = _.find(router.config.patterns, (p) => {return p.page === guid})

    let config = router.config
    const newPattern = (config.type === 'private' ? '/' : '/{userName}/') + newSuffix
    delete config.patterns[pattern]
    _.set(config, 'patterns[' + newPattern + ']', patternData)

    await editorSDK.routers.update(token, {
        routerRef,
        config
    })
    return newPattern
}

async function getId(editorSDK, appToken, routerRef) {
    const router = await editorSDK.routers.get(appToken, {routerRef})
    return router.id
}

async function getAll(editorSDK, appToken) {
    const routersArr = await editorSDK.routers.getAll(appToken)
    return routersArr
}

async function add(editorSDK, appToken, routerDef, retry = 0, e) {
    if (retry > 3) {
        throw new Error('Can not add router, failed after 3 retries, reason: ' + e && e.toString())
    }

    const retryAddingRouter = async (previousError) => {
        // Sometimes SDK adds a router but still throws some kind of error, and this method retries.
        // If router is already added in our app scope, let's not add it again
        const existingRouter = await editorSDK.routers.getByPrefix(appToken, {prefix: routerDef.prefix})

        if (existingRouter) {
            return existingRouter
        }

        let {prefix: routerPrefix} = routerDef
        routerPrefix = routerPrefix.replace(/\-[0-9]$/, '')
        routerPrefix += `-${++retry}`
        routerDef.prefix = routerPrefix
        return add(editorSDK, appToken, routerDef, retry, previousError)
    }

    const router = await editorSDK.routers.add(appToken, routerDef).catch(retryAddingRouter)

    // Sometimes SDK resolves a router but does not actually add it because of pageUriSEO is already taken
    const addedRouter = await editorSDK.routers.get(appToken, {routerRef: router})
    if (!addedRouter) {
        return await retryAddingRouter('Router was not added although the installation was successful')
    }

    return router
}

async function removeAllRouters(editorSDK, appToken) {
    const routers = await editorSDK.routers.getAll(appToken)
    for (let routerData of routers) {
        const routerRef = await editorSDK.routers.getByPrefix(appToken, {prefix: routerData.prefix})
        await editorSDK.routers.remove(appToken, {routerRef})
    }
}

async function removeConnectedPages(editorSDK, appToken) {
    const routersArr = await getAll(editorSDK, appToken)
    for (let routerData of routersArr) {
        const routerRef = await editorSDK.routers.getByPrefix(appToken, {prefix: routerData.prefix})
        for (let page of routerData.pages) {
            await editorSDK.routers.pages.remove(appToken, {
                routerRef,
                pageRef: page.pageRef
            })
        }
    }
}

async function removePageByAppDefinitionId(editorSDK, appToken, appDefinitionId, pageToNavigateAfterRemove) {
    const routers = await getAll(editorSDK, appToken)
    for (let router of routers) {
        const pattern = _.find(router.config.patterns, p => _.get(p, 'appData.appDefinitionId') === appDefinitionId)//eslint-disable-line
        if (pattern) {
            for (let page of router.pages) {
                if (page.pageRoles.includes(pattern.page)) {
                    const pageRef = page.pageRef
                    const routerRef = await editorSDK.routers.getByPrefix(appToken, {prefix: router.prefix})
                    await editorSDK.routers.pages.remove(appToken, {routerRef, pageRef, pageToNavigateAfterRemove})
                }
            }
        }
    }
}

async function findPageRefByAppData(editorSDK, appToken, appDefinitionId, appPageId) {
    const routers = await getAll(editorSDK, appToken)
    for (let router of routers) {
        const pattern = _.find(router.config.patterns, p => _.get(p, 'appData.appDefinitionId') === appDefinitionId && _.get(p, 'appData.appPageId') === appPageId)//eslint-disable-line
        if (pattern) {
            for (let page of router.pages) {
                if (page.pageRoles.includes(pattern.page)) {
                    return page.pageRef
                }
            }
        }
    }
    throw new Error(`Could not find member page for TPA ${appDefinitionId} and section ${appPageId}`)
}


const getPageByRole = (pageRole, routers) => routers
    .filter(router => router.config && router.config.patterns)
    .flatMap(router => Object.values(router.config.patterns))
    .find(pattern => pattern.page === pageRole)

const getPatternByPageRef = (pageRef, router) => {
    const pageRoles = router.pages.find(page => page.pageRef?.id === pageRef.id).pageRoles
    if (!pageRoles?.length || !router.config.patterns) {
        return;
    }
    const pattern = Object.keys(router.config.patterns).find(pattern => pageRoles.includes(router.config.patterns[pattern]?.page))

    return pattern
}

function createNewPageRoute(allRoutes, slug = NEW_PAGE_ROUTE) {
    let route = `/${slug}`
    let i = 1
    while (allRoutes.includes(route)) {
        route = `/${slug}-${i}`
        i++
    }
    return route.substring(1)
}

function getRouterRefByPrefix({editorSDK, appToken, prefix}) {
    return editorSDK.routers.getByPrefix(appToken, {prefix})
}

// To do: rename to connectPageToRouter when the other method is deleted
function connectPageRefToRouter({editorSDK, appToken, pageRef, pageRoles, routerRef}) {
    return editorSDK.routers.pages.connect(appToken, {pageRef, routerRef, pageRoles})
}

function updateRouterConfig({editorSDK, appToken, config, routerRef}) {
    return editorSDK.routers.update(appToken, {config, routerRef})
}

function removeRouterPage({editorSDK, appToken, routerRef, pageRef, pageToNavigateAfterRemove}) {
    return editorSDK.routers.pages.remove(appToken, {routerRef, pageRef, pageToNavigateAfterRemove})
}

function getByPageRef({editorSDK, pageRef}) {
    return editorSDK.document.routers.getByPage('', {pageRef})
}

function getByRef({editorSDK, routerRef}) {
    return editorSDK.document.routers.get('', {routerRef})
}

export {
    add,
    getByPageRef,
    getByRef,
    changePagePattern,
    connectPageToRouter,
    createNewPageRoute,
    findPageRefByAppData,
    getAll,
    getId,
    getPageRouterData,
    onRouterPageDelete,
    removeAllRouters,
    removeConnectedPages,
    removePageByAppDefinitionId,
    updatePageData,
    getRouterRefByPrefix,
    connectPageRefToRouter,
    updateRouterConfig,
    removeRouterPage,
    getPageByRole,
    getPatternByPageRef
}
