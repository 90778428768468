import _ from 'lodash'

import * as constants from '../constants'
import {log} from '../../utils/monitoring'
import * as componentsWrapper from '../wrappers/components'

async function createController(editorSDK, appToken, masterRef) {
    //todo - validate - "there can be only one"
    const compDef = constants.CONTROLLER_COMP_DEF
    return await editorSDK.components.add(appToken, {pageRef: masterRef, componentDefinition: compDef, customId: constants.CONTROLLER_COMP_CUSTOM_ID})
}

async function getController(editorSDK, appToken) {
    const controllers = await editorSDK.controllers.listControllers(appToken, {})
    return _.head(controllers).controllerRef
}

async function getAllControllers(editorSDK) {
    return await editorSDK.controllers.listAllControllers()
}

function filterDuplicateControllers(controllerList) {
    const isUniqueController = (ref, idx, arr) => arr.findIndex(ref2 => ref2.controllerRef.id === ref.controllerRef.id) === idx
    return controllerList.filter(isUniqueController)
}

async function getAllMasterControllers(editorSDK) {
    const allControllersRefs = await getAllControllers(editorSDK)

    const masterControllersRefs = await Promise.all(
        allControllersRefs.map(async ({controllerRef}) => {
            const isSlave = controllerRef.id.includes('_r_')
            if (isSlave) {
                const templateControllerRef = await editorSDK.components.refComponents.getTemplateComponent('', {componentRef: controllerRef})
                return {controllerRef: templateControllerRef}
            } else {
                return {controllerRef: controllerRef}
            }
        }))
    const uniqueMasterControllersRefs = filterDuplicateControllers(masterControllersRefs)

    return uniqueMasterControllersRefs
}

async function removeConnectedComponents(editorSDK, appToken, controllerRef) {
    let componentsRefs = await editorSDK.controllers.listConnectedComponents(appToken, controllerRef)
    for (let componentRef of componentsRefs) {
        await editorSDK.components.remove(appToken, {componentRef})
    }
}

async function remove(editorSDK, appToken, controllerRef) {
    await editorSDK.components.remove(appToken, {componentRef: controllerRef.controllerRef})
}

async function wipeOut(editorSDK, appToken) {
    const controllersRefs = await getAllMasterControllers(editorSDK)
    for (let controllerRef of controllersRefs) {
        //why don't I see a verticalMenu here?
        //because only verticalMenus currently rendered are returned... Huston we have a problem!!!
        await removeConnectedComponents(editorSDK, appToken, controllerRef)
        await remove(editorSDK, appToken, controllerRef)
    }

    const appDefIdsToRemove = [
        constants.PROFILE_WIDGET_APP.appDefinitionId,
        constants.ALL_MEMBERS_APP_DEF_ID
    ]
    await componentsWrapper.removeComponentsByAppDefIds(editorSDK, appDefIdsToRemove)
}

async function connectToController({editorSDK, appToken, connectToRef, controllerRef, role, connectionConfig = {}, isPrimary = true}) {
    try {
        await editorSDK.controllers.connect(appToken, {
            connectToRef,
            controllerRef,
            connectionConfig,
            role,
            isPrimary
        })
    } catch (e) {
        const tags = {platformMessage: e.toString()}
        const extra = {args: JSON.stringify({connectToRef, controllerRef, role, connectionConfig, isPrimary})}
        log('Failed to connect to controller', {tags, extra})
        throw e
    }

}

export {
    connectToController,
    createController,
    getController,
    wipeOut,
}
